$color-waiting: white;
$color-order: yellow;
$color-acknowledged: green;
$color-preparation: orange;
$color-ready: red;
$color-delivery: blue;
$color-cancelled: transparent;
$color-lost: darkgray;
$color-completed: gray;
$color-transit: purple;

$driver-active-color: green;
$driver-online-color: red;
$driver-offline-color: gray;

@mixin status-color-list {
    &.status-Waiting {
        background-color: $color-waiting;
    }

    &.status-Order {
        background-color: $color-order;
    }

    &.status-Acknowledged {
        background-color: $color-acknowledged;
    }

    &.status-Preparation {
        background-color: $color-preparation;
    }

    &.status-Ready {
        background-color: $color-ready;
    }

    &.status-Delivery {
        background-color: $color-delivery;
    }

    &.status-Cancelled {
        background-color: $color-cancelled;
    }

    &.status-Lost {
        background-color: $color-lost;
    }

    &.status-Completed {
        background-color: $color-completed;
    }

    &.status-Transit {
        background-color: $color-transit;
    }
}

.punchmode-color-list.punchmode-driver {
}

.punchmode-color-list.punchmode-btob {
    background-color: blue;
}

.punchmode-color-list.punchmode-btob-own {
    background-color: #396835;
}

.punchmode-color-list.punchmode-hubscan {
    background-color: #663634;
}

@font-face {
    font-family: 'WeblySleekUISemilight';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/weblysleekuisl.eot');
    src: url('../fonts/weblysleekuisl.eot?#iefix') format('embedded-opentype'),
        url('../fonts/weblysleekuisl.woff') format('woff'), url('../fonts/weblysleekuisl.ttf') format('truetype'),
        url('../fonts/weblysleekuisl.svg#WeblySleekUISemilight') format('svg');
}

// Layout
html,
body {
    font-family: 'WeblySleekUISemilight', 'Arial Narrow', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.react-confirm-alert-overlay {
    z-index: 100000 !important;
    white-space: pre-line;
}

.hidden {
    display: none;
}

/*#region TABLE-VIEW */
.calendar-no-icon {
    input[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
}
/*#endregion TABLE-VIEW */

.wide-modal {
    max-width: 800px;
}

.modal-dialog {
    max-width: fit-content;
}
//END Layout
// Background colors
.bg-theme {
    background-color: #52b848;
}

.bg-light {
    padding-top: 0;
    padding-bottom: 0;
}

.bg-light-gray {
    background-color: #f8f9fa !important;
}

.bg-light,
.bg-success {
    background-color: #52b848 !important;
}

.bg-gray {
    background-color: #888 !important;
}

.bg-dark {
    background-color: #1e1e1e !important;
}

.bg-dark.left-nav {
    background-color: #2f2f2f !important;
}

.bg-dark.left-nav .nav-link svg {
    font-size: 1.25rem;
}
//END Background colors

// Blink animation
.blink {
    animation: blinker 1.2s cubic-bezier(0.2, 0, 0.2, 0.2) infinite alternate;
}

@keyframes blinker {
    to {
        opacity: 0.2;
    }
}
//END Blink animation

// Line Heights and Margins
.lh-0 {
    line-height: 0;
}

.ml-50 {
    margin-left: 50px;
}

.ml-15 {
    margin-left: 15px;
}

.mr-15 {
    margin-right: 15px;
}
//END Line Heights and Margins

// Text styling
.strong {
    font-weight: 600;
}

.text-light li.bg-success a.text-light.nav-link.active,
.text-light li.bg-success a.text-light.nav-link.active:hover {
    color: white !important;
}

.text-small {
    font-size: 10px;
}

.color-theme {
    color: #52b848;
}

.text-green {
    color: #52b848;
}

.text-orange {
    color: orange;
}

.text-yellow {
    color: yellow;
}

.text-red {
    color: red;
}

.text-dark {
    color: #555;
}

.text-blue {
    color: blue;
}

.text-lightBlue {
    color: dodgerblue;
}

.text-violet {
    color: #a91ed5;
}

.text-white {
    color: white;
}

.text-grey {
    color: #cccccc;
}

.display-title {
    font-size: 1.25rem;
    line-height: 1.25rem;
}

.display-title-larger {
    font-size: 1.75rem;
    line-height: 1.75rem;
}
//END Text styling

// Task Lists
.task-list-font-span {
    display: inline;
    font-size: 16px;
}

.task-list-merchant-name {
    font-size: 9px;
}

.task-list-item .order-seq-num.h4 {
    font-size: 1.5rem;
    margin: 0;
    line-height: 1;
}

.task-list-item .status-circle {
    border-radius: 50%;
    border: 3px solid transparent;
    width: 40px;
    height: 40px;
    line-height: 2rem;
    text-align: center;
    font-size: 0.625rem;
}

.task-priority {
    font-size: 1em;
    margin-right: 0.25em;
    min-width: 20px;
    justify-content: center;
}

.driver-task-list {
    padding: 0.5rem 0;
}

.driver-task-list:hover {
    cursor: pointer;
}

.driver-task-list .btn-link {
    padding: 0;
    color: white;
}

.driver-task-list .btn-link:hover,
.driver-task-list .btn-link:focus {
    text-decoration: none;
}

.driver-task-list .badge.badge-secondary {
    background-color: #52b848;
}

.driver-task-list .task-list-chat {
    padding: 0 0.5rem;
}

.driver-task-list .task-list-chat:hover {
    color: #52b848;
}

.driver-status-icon {
    color: $driver-offline-color;

    &.online {
        color: $driver-online-color;
    }

    &.online.active {
        color: $driver-active-color;
    }
}

.driver-status-icon.contract-driver {
    background: #fdb650;
    border-radius: 10px;
}

.task-drag-handle.on-active svg:hover {
    color: #ffffff;
}

.multi-task-item.pickup-task-item,
.multi-task-item.delivery-task-item {
    background-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.multi-task-item.pickup-task-item .multi-task-item-header,
.multi-task-item.delivery-task-item .multi-task-item-header {
    background-color: transparent;
}
//END Task Lists

// Order List - Hub List
.order-list {
    margin-left: 1rem;
}

.list-group-item.dispatch-drivers {
    padding: 0.5rem;
    line-height: 1rem;
}

.list-group-item.dispatch-drivers svg,
.list-group-item svg.driver-icon {
    font-size: 1.25rem;
    margin: 0.5rem 0;
}

.list-group-item.dispatch-drivers.active svg.text-success {
    color: white !important;
}

.order-list.btn-group .btn-secondary,
.order-list.btn-group .btn-secondary:not(:disabled):not(.disabled):active,
.order-list.btn-group .btn-secondary:not(:disabled):not(.disabled).active,
.task-list-btn.btn-secondary,
.task-list-btn.btn-secondary:not(:disabled):not(.disabled):active,
.task-list-btn.btn-secondary:not(:disabled):not(.disabled).active {
    padding: 0.25rem;
    margin-right: 0.5rem;
}

.order-list.btn-group .btn-secondary.btn-none,
.order-list.btn-group .btn-secondary.btn-none:not(:disabled):not(.disabled):active,
.order-list.btn-group .btn-secondary.btn-none:not(:disabled):not(.disabled).active,
.task-list-btn.btn-secondary.btn-none,
.task-list-btn.btn-secondary.btn-none:not(:disabled):not(.disabled):active,
.task-list-btn.btn-secondary.btn-none:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}

.order-list.btn-group .btn-secondary.btn-dropdown-white,
.order-list.btn-group .btn-secondary.btn-dropdown-white:not(:disabled):not(.disabled):active,
.order-list.btn-group .btn-secondary.btn-dropdown-white:not(:disabled):not(.disabled).active {
    color: #495057;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    box-shadow: none;
}

.order-list .form-control,
.order-list .form-control:not(:disabled):not(.disabled):active,
.order-list .form-control:not(:disabled):not(.disabled).active {
    margin-right: 0.5rem;
}

.list-group.list-dark .list-group-item {
    margin: 0;
    padding: 0.5rem;
    background-color: transparent;
    border: none;
    color: #ffffff;
}

.list-group.list-dark .list-group-item a {
    color: #ffffff;
    text-decoration: none;
}

.list-group.list-dark .list-group-item a.active,
.list-group.list-dark .list-group-item a.active:hover {
    background-color: #52b848;
}

.list-group.list-dark > .list-group-item:hover,
.btn-primary.dark-bg:hover {
    background-color: #2f2f2f;
}

.hub-list .list-group-item.active {
    background-color: #52b848;
    border-color: #52b848;
}
//END Order List - Hub List

// NavBar
.navbar .btn-outline-secondary,
.navbar .btn-secondary,
.navbar .btn-outline-success,
.navbar .btn-outline-success:not(:disabled):not(.disabled):active,
.navbar .btn-outline-success:not(:disabled):not(.disabled).active {
    border: none;
    background-color: transparent;
    outline: none;
    color: white;
    padding: 0.5rem 0.75rem;
    box-shadow: none;
}

.navbar .btn-outline-success:focus,
.navbar .btn-outline-success.focus,
.navbar .btn-outline-secondary:focus,
.navbar .btn-outline-secondary.focus,
.navbar .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.navbar .btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
    box-shadow: none;
}

.navbar .btn-outline-secondary,
.navbar-light .navbar-text,
.navbar-light .navbar-brand,
.navbar-light .navbar-brand:hover {
    color: #ffffff;
}

.navbar .btn-outline-success,
.navbar .btn-outline-secondary:not(:disabled):not(.disabled):active,
.navbar .btn-outline-secondary:not(:disabled):not(.disabled).active {
    color: #52b848;
    background-color: #e9ecef;
    border: none;
    padding: 0.5rem 0.75rem;
}

.navbar .btn-outline-secondary svg,
.navbar .btn-outline-success svg {
    font-size: 1.5rem;
    margin: 0;
}

.nav-tabs.dark-bg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.nav-tabs.dark-bg,
.nav-tabs.dark-bg .nav-link,
.nav-tabs.white-bg,
.nav-tabs.white-bg .nav-link {
    border: none;
    background: none;
    text-transform: uppercase;
    outline: none;
}

.nav-tabs.dark-bg,
.nav-tabs.dark-bg .nav-link {
    color: #888888;
}

.nav-tabs.dark-bg .nav-link:hover {
    color: #bdbdbd;
}

.nav-tabs.white-bg,
.nav-tabs.white-bg .nav-link {
    color: #cccccc;
    cursor: pointer;
}

.nav-tabs.white-bg .nav-link:hover {
    color: #76787a;
}

.nav-tabs.dark-bg .nav-link,
.nav-tabs.white-bg .nav-link {
    padding: 0.5rem 0.5rem;
}

.nav-tabs.dark-bg .nav-link.active,
.nav-tabs.dark-bg .nav-item.show .nav-link,
.nav-tabs.white-bg .nav-link.active,
.nav-tabs.white-bg .nav-item.show .nav-link {
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs.dark-bg .nav-link.active,
.nav-tabs.dark-bg .nav-item.show .nav-link {
    color: #ffffff;
}

.nav-tabs.white-bg .nav-link.active,
.nav-tabs.white-bg .nav-item.show .nav-link {
    color: #212529;
}
//END NavBar

//Buttons
.btn-primary.dark-bg,
.btn-primary.dark-bg:not(:disabled):not(.disabled):active,
.btn-primary.dark-bg:not(:disabled):not(.disabled).active {
    background-color: transparent;
    border-color: transparent;
    border: none;
    box-shadow: none;
}
// .btn-primary,
// .btn-primary:not(:disabled):not(.disabled):active,
// .btn-primary:not(:disabled):not(.disabled).active {
//     background-color: #52b848;
//     border-color: #52b848;
// }

// .btn-primary:hover {
//     background-color: #4ca440;
//     border-color: #4ca440;
// }

.btn-primary.dark-bg svg {
    color: #52b848;
}

.btn-primary svg,
.btn-primary.dark-bg svg,
.btn-secondary svg {
    margin-right: 0.25rem;
}

.btn-secondary.btn-light-grey svg {
    margin-right: 0;
}

.btn-secondary.btn-light-grey,
.btn-secondary.btn-light-grey:not(:disabled):not(.disabled):active,
.btn-secondary.btn-light-grey:not(:disabled):not(.disabled).active {
    background-color: #e9ecef;
    border-color: #ced4da;
    color: #495057;
    padding: 0.25rem;
}
// END Buttons

// Maps
.border-circle {
    display: block;
    border-radius: 50%;
    border: 3px solid transparent;
    width: 40px;
    height: 40px;
    line-height: 2rem;
    text-align: center;
    font-size: 0.625rem;
}

.map-marker-light {
    height: 10px;
    width: 10px;
    position: absolute;
    left: -4px;
    top: -5px;
}

.map-marker-name {
    background-color: green;
    color: white;
    z-index: 100;
    padding: 1px;
    top: -11px;
    left: -4px;
    position: absolute;
    font-weight: 700;
    white-space: nowrap;
}

.map-marker {
    left: -0rem;
    top: 0.5rem;
    zoom: 0.7;
}

.map-marker.highlighted .card.no-border {
    background-color: #52b848;
    border: 1px solid rgba(0, 0, 0, 0.33);
}

.map-marker .map-marker-info {
    left: -0.2rem;
    top: -1.3rem;
    font-weight: 700;
    background-color: black;

    .card {
        background-color: #1e1e1e;
        border: 1px solid rgba(255, 255, 255, 0.33);
    }
}

.map-marker .map-marker-info .driver-status-icon {
    font-size: 125%;
    vertical-align: -0.2rem;
}

.map-marker .map-marker-pos {
    position: absolute;
    z-index: 2001;
    width: 16px;
    height: 9px;
    margin-top: 1px;
    background-image: url('../assets/map-chevron-black.png');
}

.map-marker.highlighted .map-marker-pos {
    background-image: url('../assets/map-chevron-green.png');
}

.map-filter-panel {
    min-width: 24rem;
    max-width: 26rem;
}
//END Maps

.comment-fields {
    padding-left: 30px;
}

// Status Icons
.status-Waiting .border-circle {
    border-color: $color-waiting;
}

.status-Order .border-circle {
    border-color: $color-order;
}

.status-Acknowledged .border-circle {
    border-color: $color-acknowledged;
}

.status-Preparation .border-circle {
    border-color: $color-preparation;
}

.status-Ready .border-circle {
    border-color: $color-ready;
}

.status-Delivery .border-circle {
    border-color: $color-delivery;
}

.status-Cancelled .border-circle {
    border-color: $color-cancelled;
}

.status-Lost .border-circle {
    border-color: $color-lost;
}

.status-Completed .border-circle {
    border-color: $color-completed;
}

.status-Transit .border-circle {
    border-color: $color-transit;
}

.status-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: transparent;
    border: solid 1px black;

    @include status-color-list;
}
//END Status Icons

.warning-light:before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    background-color: red;
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.order-menu-item {
    display: inline-block;

    .order-menu-icon {
        display: inline-block;
        margin-right: 0.5em;
        width: 1em;
        height: 1em;

        &.order-menu-status-icon {
            border: solid 1px black;
        }

        @include status-color-list;
    }
}

// Chat panel
.chat-panel {
    .list-group-item {
        margin-top: -1px;
        margin-bottom: 0;
        padding: 0.5rem;
        background-color: #e9ecef;

        &:hover {
            background-color: #f1f3f5;
        }

        .recipient-name {
            font-size: 1.125rem;
        }

        svg {
            font-size: 1.25rem;
            margin-right: 0.25rem;
            vertical-align: middle;
        }
    }

    .conversation {
        background-color: white;
        border: 1px solid #ced4da;
        border-top: none;

        .message-text {
            border-radius: 5px;
            padding: 10px;
        }

        .sender-container {
            background-color: #e9ecef;
            border-bottom: 1px solid #ced4da;
            padding: 0.5rem;
            margin-bottom: 1rem;

            svg {
                margin: 0;
            }

            span {
                font-size: 1.125rem;
            }
        }

        .send-message-container {
            background-color: #e9ecef;
            border-top: 1px solid #ced4da;
            padding: 0.5rem;
        }

        .thread-message {
            margin-bottom: 1rem;
        }

        .thread-message.received-message {
            background: none !important;
            color: initial !important;

            .message-text {
                background-color: #e5f4e4;
            }
        }

        .thread-message.sent-message {
            background: none !important;
            color: initial !important;

            .message-text {
                background-color: #e9ecef;
            }
        }
    }
}
//END Chat panel

#table-sorting {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    zoom: 1.4;
    background-color: #1f1f1f;

    th {
        font-size: 1.3rem;
        position: sticky;
        top: 0;
        background-color: #1f1f1f;
    }
}

.order-info-pill {
    display: inline-block;
    padding: 0.25em 0.5em;
}
